<template>
    <div>
        <div class="container pt">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                            <li>
                                <router-link :to="{name: 'index'}">
                                    {{ $t('appname') }}
                                </router-link>
                            </li>
                            <li>{{ $t('version') }} {{ safeName }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t('version') }} {{ safeName }}</h1>
                </div>
                <div class="col-12 mb">
                    <router-link
                        class="link-icon-prev"
                        :to="{name: 'index'}"
                    >
                        <span class="text-bold">{{ $t('viewer.navLink.backToOverview') }}</span>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <iframe
                        id="viewer"
                        allowfullscreen
                        allow="fullscreen"
                        class="pdf-reader"
                        :src="getIframeUrl()"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: { },
    name: 'EpaperView',
    props: {
        file: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        }
    },
    computed: {
        safeName: function() {
            return this.name.replace(/[^0-9a-z_-]/gi, ' ')
        },
        rowCount:function() {
            return Math.ceil(this.epapers.length / this.itemsPerRow)
        },
        archivedEpapers:function() {
            return this.epapers.reverse()
        }
    },
    methods: {
        getIframeUrl() {
            // return `https://localhost:8503/assets/e-papers/reader/v4/viewer.html?file=/../../assets/e-papers/${this.file}`
            return `/portal/creditreform-magazin/assets/e-papers/reader/v4/viewer.html?file=/portal/creditreform-magazin/assets/e-papers/${this.file}`
        }
    },
    mounted () {
        if (!this.file || !this.name) {
            this.$router.push({name: 'index'})
        }

        let isFullscreen = false,
            frame = document.getElementById('viewer')

        function closeFullscreen() {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
          }
        }

        window.addEventListener('message', (event) => {
            if (event.data === 'toggleFullscreen') {
                if (!isFullscreen) {
                    frame.contentWindow.postMessage('openFullscreen', "*");
                    isFullscreen = true
                } else {
                    if (document.fullscreenElement) {
                        closeFullscreen()
                    }
                    frame.contentWindow.postMessage('closeFullscreen', "*");
                    isFullscreen = false
                }
            }
        }, false)
    }
}
</script>

<style scoped>
    .pdf-reader {
        width: 100%;
        height: auto;
        min-height: 629px;
        border: none;
        z-index: 2;
    }
</style>
